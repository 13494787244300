import { ChangeEvent, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import Switch from "react-switch";
import { Context } from "../../App";
import Button from "../../components/Button/Button";
import FieldsetLayout from "../../components/FieldsetLayout/FieldsetLayout";
import FileInput from "../../components/FileInput/FileInput";
import styles from "../../components/Table/Table.module.css";
import TextField from "../../components/TextField/TextField";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { usePut } from "../../hooks/usePut";
import { uploadPromotionalCodes } from "../../services/ProductService";
import { nonSpecialCharactersValidation } from "../../utils/formValidations.utils";
import stylesClient from "../Clients/Clients.module.css";
import { EditProductRewardDto } from "./product.types";
import rewardStyles from "./ProductRewards.module.css";
import { ProductRewardResponse } from "./useGetProductRewards";

type RewardFormData = {
  id: number;
  title: string;
  active: boolean;
  uniqueCode: string;
};

interface LocationState {
  productReward: ProductRewardResponse;
}

export default function EditProductReward() {
  const location = useLocation();
  const { productReward } = location.state as LocationState;
  const { title, active, requiresShipping, uniqueCode } = productReward;

  const [loadingUpload, setLoadingUpload] = useState(false);
  const context = useContext(Context);

  const navigate = useNavigate();
  const [status, setStatus] = useState<boolean>(active);
  const { putData, isLoading, error } = usePut<EditProductRewardDto, any>();

  const handleChange = (checked: boolean) => {
    setStatus(checked);
  };

  const { handleSubmit, control } = useForm<RewardFormData>({
    defaultValues: {
      title,
      active,
      uniqueCode,
    },
  });

  const onSubmit: SubmitHandler<RewardFormData> = async (
    data: RewardFormData,
    e: any
  ) => {
    e.preventDefault();

    const { title, uniqueCode } = data;

    putData(`products/reward/${productReward.id}`, {
      title,
      active: status,
      uniqueCode,
    })
      .then((_response) => {
        context?.showAcceptModal(
          "Éxito",
          "Cambios guardados correctamente",
          () => navigate(-1)
        );
      })
      .catch(() =>
        context?.showErrorModal(
          "Error",
          "Ha ocurrido un error al guardar los cambios"
        )
      );
  };

  const handlePromotionalCode = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoadingUpload(true);
      let file = e.target.files[0];
      const formData = new FormData();
      if (file) formData.append("file", file);

      await uploadPromotionalCodes(productReward, formData)
        .then((_response) => {
          context?.showAcceptModal(
            "Éxito",
            "Codigos promocionales cargados correctamente.",
            () => navigate(-1)
          );
        })
        .catch(() =>
          context?.showErrorModal(
            "Error",
            "Ha ocurrido un error al subir los códigos."
          )
        );
      setLoadingUpload(false);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <TitleContainer title="Recompensa" backURL="/rewards">
      <FieldsetLayout label="Editar Recompensa">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={rewardStyles.flexContainer}
        >
          <TextField
            label="Nombre"
            props={{
              name: "title",
              control,
              rules: {
                required: true,
                pattern: nonSpecialCharactersValidation,
              },
            }}
          />

          <div className={rewardStyles.switchContainer}>
            <span>Activa</span>
            <Switch
              onChange={handleChange}
              checked={status}
              className={styles.reactSwitch}
            />
          </div>
          {requiresShipping && (
            <div className={rewardStyles.switchContainer}>
              <span>Recompensa con entrega a domicilio</span>
              <Switch
                checked={true}
                onChange={() => {}}
                className={styles.reactSwitch}
                readOnly={true}
                disabled={true}
              />
            </div>
          )}
          {uniqueCode && (
            <div className={rewardStyles.switchContainer}>
              <span>Recompensa con código único:</span>
              <Switch
                checked={true}
                onChange={() => {}}
                className={styles.reactSwitch}
                readOnly={true}
                disabled={true}
              />
              <TextField
                placeholder="Ingrese código"
                row
                props={{
                  name: "uniqueCode",
                  control,
                  rules: {
                    required: true,
                  },
                }}
              />
            </div>
          )}
          <div className={stylesClient.btn}>
            <Button
              text="Editar"
              type="submit"
              styleOverride={{ margin: "0 auto" }}
            />
          </div>
        </form>
      </FieldsetLayout>
      {!requiresShipping && !uniqueCode && (
        <FieldsetLayout label="Códigos promocionables">
          <div
            style={{
              width: loadingUpload ? "fit-content" : "100%",
              margin: "0 auto",
            }}
          >
            {!loadingUpload && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    color: "#f2c233",
                    fontSize: "1.1rem",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                  }}
                >
                  Esta recompensa tiene {productReward.coupons} códigos
                  promocionables <br/>
                  <span style={{fontSize: "0.9rem"}}>

                  *El archivo debe ser formato .xlsx y contener los códigos únicamente en la primera columna, sin encabezados.
                  </span>
                </div>
                <FileInput
                  handleFileChange={handlePromotionalCode}
                  inputName="promotionalCodes"
                  label={"Agregar códigos promocionbles"}
                  accept=".xlsx"
                />
              </div>
            )}
            <SyncLoader color="#f2c233" loading={loadingUpload} size={15} />
          </div>
        </FieldsetLayout>
      )}
    </TitleContainer>
  );
}
