import { formatCurrency } from "../../utils/format.utils";
import styles from "./Progress.module.css";


export default function Progress({ percentage, totalRaised, objectiveNumber }: { percentage: number, totalRaised: number, objectiveNumber: number }) {
  const minimPercentage = 5
  return (
    <div className={styles.container} id="progressBar">
      <div className={styles.barContainer}>
        <div className={styles.progress} style={{ width: `${percentage.toString()}%` }}>
          <div className={styles.percentageOut}>
            {percentage > minimPercentage && `${percentage.toString()}% (${formatCurrency(totalRaised, 'es')})`}
          </div>
        </div>
        <div className={styles.percentageIn}>
          {percentage <= minimPercentage && `${percentage.toString()}% (${formatCurrency(totalRaised, 'es')})`}
        </div>
      </div>
      <p>{formatCurrency(objectiveNumber, 'es')}</p>
    </div>
  )
}
