import {
  ProductRewardResponse,
  useGetProductRewards,
} from "./useGetProductRewards";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import Button from "../../components/Button/Button";
import Spacer from "../../components/Spacer/Spacer";
import { ReactComponent as Edit } from "../../images/edit.svg";
import styles from "../../components/Table/Table.module.css";

function ProductRewards() {
  const { rewards, isLoading, error } = useGetProductRewards();
  const navigate = useNavigate();

  const handleEdit = (productReward: ProductRewardResponse) => {
    navigate("/rewards/edit", { state: { productReward } });
  };

  if (isLoading || !rewards) {
    return <Loading show={true} />;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <TitleContainer title="Recompensas">
      <Button
        disabled={false}
        text={"Nuevo"}
        onClick={() => {
          navigate("/rewards/create");
        }}
        styleOverride={{ marginLeft: "auto" }}
      />
      <Spacer space="40px"></Spacer>
      <table className={styles.container}>
        <thead>
          <tr>
            <th>Descripción</th>
            <th style={{ textAlign: "center" }}>Entrega a Domicilio</th>
            <th style={{ textAlign: "center" }}>Código/s</th>
            <th style={{ textAlign: "center" }}>Editar</th>
          </tr>
        </thead>
        <tbody>
          <>
            {rewards!.map((data, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor: !data.active
                    ? "#f64d4d"
                    : undefined,
                }}
              >
                <td>{data.title}</td>
                <td style={{ textAlign: "center" }}>{data.requiresShipping ? "Si" : "No"}</td>
                <td style={{ textAlign: "center" }}>{!data.requiresShipping && (data.uniqueCode || data.coupons)}</td>
                <td style={{ cursor: "pointer", textAlign: "center" }}>
                  <Edit onClick={() => handleEdit(data)} />
                </td>
              </tr>
            ))}
          </>
        </tbody>
      </table>
    </TitleContainer>
  );
}

export default ProductRewards;
