import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import Button from "../../components/Button/Button";
import CampaignFilters from "../../components/CampaignFilters/CampaignFilters";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import { getCampaigns } from "../../services/DashboardServices";
import { getContributorsToExcel, getContributors } from "../../services/ReportServices";
import { getCampaignDetail } from "../../services/CampaignServices";
import { handleUnahutorizedException } from "../../utils/servicesUtils";
import { CampaignResponse } from "../Campaign/campaign.types";
import { Contributor, ReportForm } from "./report.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import styles from "./Report.module.css";
import stylesTable from "../../components/Table/Table.module.css";
import stylesButton from "../../components/Button/Button.module.css";

export default function Reports() {
  const [campaigns, setCampaigns] = useState<CampaignResponse[]>([]);
  const [formData, setFormData] = useState<ReportForm>({
    campaign: null,
    fromDate: "",
    toDate: "",
    contributors: []
  });
  const context = useContext(Context);
  const { setShowLoading, loading } = context!;
  const navigate = useNavigate();

  const getDate = async (campaignKey: string) => {
    const data = await getCampaignDetail(campaignKey);
    const createdAt = new Date(data.createdAt);
    const fromDate = createdAt.toISOString().slice(0, 16);

    const today = new Date();
    const offset = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() - offset);
    const toDate = today.toISOString().slice(0, 16);
    return { fromDate, toDate };
  }

  useEffect(() => {
    if (!context?.getToken()) navigate("/");

    setShowLoading(true);
    getCampaigns()
      .then(async (data) => {
        if (data && data.length > 0) {
          setCampaigns(data);
          const firstCampaign = data[0];

          try {
            const { fromDate, toDate } = await getDate(firstCampaign.key);
            const contributors = await getContributorsData(firstCampaign.key, fromDate, toDate);
            setFormData({
              campaign: firstCampaign,
              fromDate,
              toDate,
              contributors
            });
          } catch (error: any) {
            handleUnahutorizedException(error, navigate);
          }
        }
      })
      .catch((error) => handleUnahutorizedException(error, navigate))
      .finally(() => setShowLoading(false));
  }, []);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setShowLoading(true);
    const { name, value } = e.target;
    
    if (name === 'campaign') {
      const selectedCampaign = campaigns[parseInt(value)];
      setFormData(prev => ({ ...prev, campaign: selectedCampaign }));

      try {
        const { fromDate, toDate } = await getDate(selectedCampaign.key);
        const contributors = await getContributorsData(selectedCampaign.key, fromDate, toDate);
        setFormData(prev => ({
          ...prev,
          fromDate,
          toDate,
          contributors
        }));
      } catch (error: any) {
        handleUnahutorizedException(error, navigate);
      }
    } else if (name === 'fromDate' || name === 'toDate') {
      const now = new Date();
      const offset = now.getTimezoneOffset();
      now.setMinutes(now.getMinutes() - offset);
      const currentTime = now.toISOString().slice(0, 16);
      
      let newFromDate = formData.fromDate;
      let newToDate = formData.toDate;

      if (name === 'fromDate') {
        newFromDate = value > currentTime ? currentTime : value;
        if (new Date(newFromDate) > new Date(formData.toDate)) {
          newToDate = newFromDate;
        }
      } else {
        newToDate = value > currentTime ? currentTime : value;
        if (new Date(newToDate) < new Date(formData.fromDate)) {
          newFromDate = newToDate;
        }
      }

      setFormData(prev => ({
        ...prev,
        fromDate: newFromDate,
        toDate: newToDate
      }));

      if (formData.campaign) {
        try {
          const contributors = await getContributorsData(formData.campaign.key, newFromDate, newToDate);
          setFormData(prev => ({
            ...prev,
            contributors
          }));
        } catch (error: any) {
          handleUnahutorizedException(error, navigate);
        }
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    setShowLoading(false);
  };

  const handleDownloadReport = async () => {
    setShowLoading(true);
    const { campaign, fromDate, toDate } = formData;
    if (campaign && fromDate && toDate) {
      const fromDateUTC = new Date(fromDate).toISOString();
      const toDateUTC = new Date(toDate).toISOString();
      getContributorsToExcel(campaign.key, fromDateUTC, toDateUTC).catch((error) => {
        handleUnahutorizedException(error, navigate);
      });
    }
    setShowLoading(false);
  };

  const getContributorsData = async (campaignKey: string, fromDate: string, toDate: string): Promise<Contributor[]> => {
    const fromDateUTC = new Date(fromDate).toISOString();
    const toDateUTC = new Date(toDate).toISOString();
    const data = await getContributors(campaignKey, fromDateUTC, toDateUTC);
    return data;
  }

  const handleRefresh = async () => {
    if (!formData.campaign) return;

    setShowLoading(true);
    try {
      const contributors = await getContributorsData(formData.campaign.key, formData.fromDate, formData.toDate);
      setFormData(prev => ({
        ...prev,
        contributors
      }));
    } catch (error: any) {
      handleUnahutorizedException(error, navigate);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <>
      <TitleContainer title="Reportes">
        <CampaignFilters
          setOption={(id: string) => handleInputChange({ target: { name: 'campaign', value: id } } as React.ChangeEvent<HTMLSelectElement>)}
        >
          {campaigns.map((campaign: CampaignResponse, i: number) => (
            <option key={i} value={i}>
              {campaign.title}
            </option>
          ))}
        </CampaignFilters>
        {!loading &&
          <div className={styles.reportContainer}>
            <div className={styles.reportHeader}>
              <div className={styles.dateInputsContainer}>
                <div className={styles.dateInputContainer}>
                  <label htmlFor="fromDate">Desde:</label>
                  <input
                    type="datetime-local"
                    id="fromDate"
                    name="fromDate"
                    value={formData.fromDate}
                    className={styles.dateInput}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.dateInputContainer}>
                  <label htmlFor="toDate">Hasta:</label>
                  <input
                    type="datetime-local"
                    id="toDate"
                    name="toDate"
                    value={formData.toDate}
                    className={styles.dateInput}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <button
                  className={stylesButton.button}
                  style={{ width: 'fit-content', gap: '5px' }}
                  onClick={handleRefresh}
                  disabled={!formData.campaign || !formData.fromDate || !formData.toDate}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </button>
                <Button
                  disabled={!formData.campaign || !formData.fromDate || !formData.toDate}
                  text={"Descargar lista de contribuyentes"}
                  onClick={handleDownloadReport}
                  styleOverride={{
                    width: "auto",
                    padding: "10px 10px",
                  }}
                />
              </div>
            </div>
            <div className={styles.reportForm}>
              <table className={stylesTable.container}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Nombre Completo</th>
                    <th>Ciudad</th>
                    <th>Dirección</th>
                    <th>Código Postal</th>
                    <th>País</th>
                    <th>Teléfono</th>
                    <th>Producto</th>
                    <th>Código de operación</th>
                    <th>Recompensas</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.contributors.map((contributor, index) => (
                    <tr key={index}>
                      <td>{contributor.email}</td>
                      <td>{contributor.fullName}</td>
                      <td>{contributor.city}</td>
                      <td>{contributor.address}</td>
                      <td>{contributor.postalCode}</td>
                      <td>{contributor.country}</td>
                      <td>{contributor.phone}</td>
                      <td>{contributor.product}</td>
                      <td>{contributor.operationCode}</td>
                      <td>{contributor.rewards}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
      </TitleContainer>
    </>
  );
}
